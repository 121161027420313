<template>
    <div class="card-contents" style="overflow-x:hidden">
        <h2>Demo-link beheer</h2>
        <h3>Module: {{module.name}}-{{module.variant}}-{{module.version}}</h3>
        <table class="table">
            <thead>
                <th>Id</th>
                <th>Omschrijving</th>
                <th>Code</th>
                <th>Verloopt</th>
                <th>Geopend</th>
                <th>Url</th>
                <th></th>
            </thead>
            <tbody>
                <tr
                    v-for="key in keys" :key="key.id"
                >
                    <td>{{key.id}}</td>
                    <td>{{key.description}}</td>
                    <td>{{key.code}}</td>
                    <td>{{key.expires}}</td>
                    <td>{{key.opened_on}}</td>
                    <td><span class="link" @click="copyUrlToClipboard(key.code)">Copy</span></td>
                    <td><span class="link" @click="resetKey(key.id)">Reset</span></td>
                </tr>
            </tbody>
            <tfoot>
                <tr>
                    <td></td>
                    <td><input name="description" v-model="newKey.description" placeholder="geef omschrijving"/></td>
                    <td></td>
                    <td><input name="expires" v-model="newKey.expires"placeholder="Verloopdatum (dd-mm-jjjj)"/></td>
                    <td>
                        <button @click="addKey">Link Toevoegen</button>
                    </td>
                    <td></td>
                </tr>
            </tfoot>
        </table>
    </div></template>

<script>
export default {
    name: "Keyform",
    data() {
        return {
            keys: [],
            errors: [],
            newKey: {},
            canEdit: false,
            module: {},
        }
    },
    props: ['baseUrl', 'moduleId'],
    mounted() {
        this.reset();
        this.getModule();
        this.getKeys();
    },

    methods: {
        reset() {
            this.newKey = {'moduleId': this.moduleId};
        },
        getKeys() {
            axios.get('/api/module/'+this.moduleId+'/keys')
                .then(response => {
                    if (response.data.result == 'success') {
                        this.keys = response.data.data;
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },

        getModule() {
            axios.get('/api/module/'+this.moduleId)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.module = response.data.data;
                        this.canEdit = response.data.canEdit;
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    if (error.response.status == 401){
                        location.href='';
                    } else {
                        alert(error);
                    }
                });
        },

        addKey() {
             if (! this.newKey.description || this.newKey.description.trim() == '')
                 alert('De omschrijving mag niet leeg zijn');
             if (! this.newKey.expires || this.newKey.expires.trim() == '')
                 alert('De verloopdatum mag niet leeg zijn');

            axios.post('/api/module/'+this.moduleId+'/keys', this.newKey)
                .then(response => {
                    if (response.data.result == 'success') {
                        this.keys.push(response.data.data);
                        this.reset();
                    } else {
                        alert(response.data.message);
                    }
                })
                .catch(error => {
                    switch (error.response.status) {
                        case 422: alert(error.response.data.message); break;
                        default:
                        case 401: alert(error.message); break;
                    }
                });
            },

            copyUrlToClipboard(key) {
                let url = this.baseUrl + '/' + this.module.name + '/' + this.module.variant + '/' + this.module.version + '?sleutel=' + key;
                navigator.clipboard.writeText(url).then(() => {
                    alert('De URL is gekopieerd naar het clipboard');
                }, () => {
                    alert('De URL kon niet worden gekopieerd');
                });
            },

            resetKey(keyId) {
                // reset the 'opened' attribute
                axios.post('/api/module/'+this.moduleId+'/keys/' + keyId + '/reset')
                    .then(response => {
                        if (response.data.result == 'success') {
                            alert('Sleutel is gereset');
                            this.getKeys();
                        } else {
                            alert(response.data.message);
                        }
                    })
                    .catch(error => {
                        alert(error.message);
                    });
            }
    }
}
</script>

<style scoped>
    .link:hover {
        cursor: pointer;
    }
</style>
