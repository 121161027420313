<template>
    <div class="card-contents" style="overflow-x:hidden">
        <h2>Module beheer</h2>
        <table class="table">
            <thead>
                <th>Id</th>
                <th>Naam</th>
                <th>Variant</th>
                <th>Versie</th>
                <th>Feedback</th>
                <th>Start</th>
                <th>Vragen</th>
                <th>Extra</th>
                <th>Score-teksten</th>
                <th>Url</th>
                <th>Demo-links</th>
            </thead>
            <tbody>
                <tr
                    :class="{selectable: canEdit}"
                    v-for="module in modules" :key="module.id"
                    @click="editModule(module)"
                >
                    <td>{{module.id}}</td>
                    <td>{{module.name}}</td>
                    <td>{{module.variant}}</td>
                    <td>{{module.version}}</td>
                    <td>
                        <div v-if="canEdit && module.feedback === 1">
                            <select @change="changeFeedback($event, module)">
                                <option value="none" :selected="module.pivot.feedback_type === 'none'">Uit</option>
                                <option value="open" :selected="module.pivot.feedback_type === 'open'">Open</option>
                                <option value="blind" :selected="module.pivot.feedback_type === 'blind'">Blind</option>
                            </select>
                        </div>
                        <div v-if="! canEdit || module.pivot.feedback_type == 0">{{feedbackIndication(module)}}</div>
                    </td>
                    <td>{{module.pivot.active_from.substring(0,10)}}</td>
                    <td>
                        <a :href="baseUrl+'/dashboard/modules/'+module.id+'/competences'">
                            {{module.competences.length}}
                        </a>
                    </td>
                    <td>
                        <a :href="baseUrl+'/dashboard/modules/'+module.id+'/polls'">
                            {{module.polls.length}}
                        </a>
                    </td>
                    <td>
                        <a :href="baseUrl+'/dashboard/storeGroup/' + module.score_group">{{module.score_group}}</a>
                    </td>
                    <td>
                        <a :href="baseUrl+'/start/'+module.url_name+'/'+module.variant+'/'+module.version">Open module</a>
                    </td>
                    <td>
                        <a v-if="module.name != 'Demo'" :href="baseUrl+'/dashboard/modules/'+module.id+'/keyform'">
                            Beheren
                        </a>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    export default {
        name: "Modules",
        data() {
            return {
                modules: [],
                errors: [],
                canEdit: false,
            }
        },

        mounted() {
            this.getModules();
        },

        props: ['baseUrl'],

        methods: {
            changeFeedback(event, module) {
                let data = {feedback_type: event.target.value};
                axios.post('/api/modules/'+module.id+'/feedback', data)
                    .then(response => {
                        if (response.data.result === 'success') {
                            alert('Feedback wijziging is opgeslagen');
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            feedbackIndication(module) {
                if (module.feedback == 1 && module.pivot.feedback_type !== 'none') {
                    return module.pivot.feedback_type;
                }
                return 'Nee';
            },
            getModules() {
                axios.get('/api/modules/')
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.modules = response.data.data;
                            this.canEdit = response.data.canEdit;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            editModule(module){
                location.href=this.baseUrl+'/dashboard/modules/'+module.id
            }
        }
    }
</script>

<style scoped>
    .selectable:hover td{
        background-color: #7f9b90;
        color: white;
    }
</style>
