<template>
    <div class="card-contents" style="overflow-x:hidden">
        <h2>Score-group beheer: {{groupName}}</h2>
        <table class="table">
            <thead>
                <th>Id</th>
                <th>Score</th>
                <th v-if="canEdit">Van %</th>
                <th v-if="canEdit">Tot %</th>
                <th>Sectie</th>
                <th>Score-text</th>
                <th>Assessor-text</th>
            </thead>
            <tbody>
                <tr
                    :class="{selectable: canEdit}"
                    v-for="scoreText in scoreTexts" :key="scoreText.id"
                    @click="editScoreGroup(scoreText)"
                >
                    <td>{{scoreText.id}}</td>
                    <td>{{scoreText.score}}</td>
                    <td v-if="canEdit">{{scoreText.from_percentage}}</td>
                    <td v-if="canEdit">{{scoreText.to_percentage}}</td>
                    <td>{{scoreText.section}}</td>
                    <td>{{scoreText.text.substring(3,40)}}…<a href="#" @click.prevent.stop="showText(scoreText.text)">Toon alles</a></td>
                    <td>{{scoreText.text_assessor.substring(3,40)}}…<a href="#" @click.prevent.stop="showText(scoreText.text_assessor)">Toon alles</a></td>
                </tr>
            </tbody>
        </table>
        <div id="text-editor" ref="textEditor" v-if="editorShown">
            <span class="close-button" @click="editorShown=false">X</span>
            <p><div v-html="textContents"></div></p>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ScoreGroup",
        data() {
            return {
                scoreTexts: [],
                errors: [],
                dialogTitle: '',
                textContents: '',
                editorShown: false
            }
        },

        mounted() {
            this.getModules();
        },

        props: ['baseUrl', 'groupName', 'canEdit'],

        methods: {
            getModules() {
                axios.get('/api/scoreGroup/' + this.groupName + '/items')
                    .then(response => {
                        if (response.data.result == 'success') {
                            this.scoreTexts = response.data.data;
                            this.canEdit = response.data.canEdit;
                        } else {
                            this.errors.push(response.data.message);
                        }
                    })
                    .catch(error => {
                        if(error.response.status == 401){
                            location.href='';
                        } else {
                            alert(error);
                        }
                    });
            },
            showText($text) {
                this.textContents = $text;
                this.editorShown = true;
            },
            editScoreGroup(scoreText){
                if (this.canEdit) {
                    location.href = this.baseUrl + '/dashboard/scoreText/' + scoreText.id
                }
            }
        }
    }
</script>

<style scoped>
    #text-editor{
        position: fixed;
        left: 80px;
        top:  200px;
        height: 400px;
        width: 550px;
        border: 1px solid gray;
        z-index: 1;
        background-color: white;
        border-radius: 8px;
        padding: 30px 15px 15px 15px;
    }
    #text-editor textarea{
        width: 100%;
        height: 100%;
    }

    .selectable:hover td{
        background-color: #7f9b90;
        color: white;
    }
    .close-button{
        position: absolute;
        top: 10px;
        right: 10px;
        font-size: larger;
        border-radius: 8px;
        border: 1px solid gray;
        width: 24px;
        height: 24px;
        text-align: center;
    }
    .close-button:hover{
        font-weight: bold;
        cursor: pointer;
        border: 2px solid black;
    }
</style>
